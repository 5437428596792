/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-case-declarations */
import React, { useEffect, useMemo, useState } from 'react';
import { Download, Visibility } from '@mui/icons-material';
import {
    Chip,
    Typography,
    Box,
    Snackbar,
    Alert,
    Autocomplete,
    FormControlLabel,
    Checkbox,
    Modal,
} from '@mui/material';
import DataTable from 'react-data-table-component';
import {
    PinheiroButton,
    PinheiroFormControl,
    PinheiroIconButton,
    PinheiroTextField,
} from 'components/PinheiroTheme';
import Spinner from 'components/Spinner';
import { useGenerateSeparations, useSeparations, useUsers } from 'hooks/useApi';
import SeparationDrawer from './partials/SeparationDrawer';
import { downloadCSV } from 'utils/download';
import { separationStatuses } from 'utils/enums';

const periodOptions = [
    { value: 'all', label: 'Todos' },
    { value: 'this-month', label: 'Este mês' },
    { value: 'last-month', label: 'Mês passado' },
    { value: 'this-week', label: 'Esta semana' },
    { value: 'last-week', label: 'Semana passada' },
];

const Separations = () => {
    const [filterDate, setFilterDate] = useState(periodOptions[0]);
    const [visible, setVisible] = useState(false);
    const [alert, setAlert] = useState({});
    const [separationId, setSeparationId] = useState(null);
    const [page, setPage] = useState(1);
    const [hideFuture, setHideFuture] = useState(true);
    const [separationModalVisible, setSeparationModalVisible] = useState(false);
    const [separationGenerationStatus, setSeparationGenerationStatus] = useState(null);
    const [lastFetchedTime, setLastFetchedTime] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);

    const { startDate, endDate } = useMemo(() => {
        if (!filterDate) {
            return {};
        }

        const today = new Date();

        switch (filterDate.value) {
            case 'this-month':
                const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
                const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

                return {
                    startDate: firstDay,
                    endDate: lastDay,
                };
            case 'last-month':
                const firstDayLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
                const lastDayLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);

                return {
                    startDate: firstDayLastMonth,
                    endDate: lastDayLastMonth,
                };
            case 'next-month':
                const firstDayNextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);
                const lastDayNextMonth = new Date(today.getFullYear(), today.getMonth() + 2, 0);

                return {
                    startDate: firstDayNextMonth,
                    endDate: lastDayNextMonth,
                };
            case 'this-week':
                const firstDayThisWeek = new Date(today.setDate(today.getDate() - today.getDay()));
                const lastDayThisWeek = new Date(today.setDate(today.getDate() - today.getDay() + 6));

                return {
                    startDate: firstDayThisWeek,
                    endDate: lastDayThisWeek,
                };
            case 'last-week':
                const firstDayLastWeek = new Date(today.setDate(today.getDate() - today.getDay() - 7));
                const lastDayLastWeek = new Date(today.setDate(today.getDate() - today.getDay() - 1));

                return {
                    startDate: firstDayLastWeek,
                    endDate: lastDayLastWeek,
                };
            case 'next-week':
                const firstDayNextWeek = new Date(today.setDate(today.getDate() - today.getDay() + 7));
                const lastDayNextWeek = new Date(today.setDate(today.getDate() - today.getDay() + 13));

                return {
                    startDate: firstDayNextWeek,
                    endDate: lastDayNextWeek,
                };
            default:
                return {};
        }
    }, [filterDate]);

    const { data: usersData, isFetching: isFetchingUsers } = useUsers();
    const { data, isLoading, isFetching, refetch } = useSeparations(!hideFuture, page, startDate, endDate, selectedUser?.value);
    const generateSeparations = useGenerateSeparations();

    useEffect(() => {
        if (!isFetching) {
            setLastFetchedTime(new Date());
        }
    }, [isFetching]);

    const {
        data: separations,
        pagination,
    } = data || {};

    const {
        data: users = [],
    } = usersData || {};

    const usersOptions = users.map((user) => ({
        value: user.user_id,
        label: `${user.first_name || ''} ${user.last_name || ''}`,
    }));

    const columns = [
        {
            name: 'Id',
            width: '70px',
            center: true,
            selector: (row) => row.separation_id,
            hide: 'sm',
        },
        {
            name: 'Operador',
            sortable: true,
            selector: (row) => row.user?.first_name
                ? `${row.user.first_name || ''} ${row.user.last_name || ''}`
                : '-',
        },
        {
            name: 'Status',
            cell: (row) => {
                const { status } = row;

                const { label, color, backgroundColor } = separationStatuses.get(status) || {};

                return (
                    (
                        <Chip
                            sx={{
                                backgroundColor,
                                fontSize: '12px',
                                color,
                            }}
                            size={'small'}
                            label={label}
                        />
                    )
                );
            },
            center: true,
        },
        {
            name: 'Início',
            selector: (row) => row.start_date
                ? new Date(row.start_date).toLocaleString('pt-BR', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                })
                : '-',
        },
        {
            name: 'Fim',
            selector: (row) => row.end_date
                ? new Date(row.end_date).toLocaleString('pt-BR', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                })
                : '-',
        },
        {
            name: 'Lojas',
            selector: (row) => Array.from(new Set(row.boxes.map((box) => box.store_id))).join(', '),
        },
        {
            name: 'Rua',
            selector: (row) => row.street || '-',
            compact: true,
            minWidth: '30px',
            grow: 0,
        },
        {
            name: 'Ações',
            maxWidth: '120px',
            center: true,
            button: true,
            cell: (row) => (
                <div className={'actions'}>
                    <PinheiroIconButton
                        onClick={() => {
                            setSeparationId(row.separation_id);
                            setVisible(true);
                        }}
                    >
                        <Visibility />
                    </PinheiroIconButton>
                </div>
            ),
        },
    ];

    const paginationComponentOptions = {
        noRowsPerPage: true,
        rangeSeparatorText: 'de',
    };

    const exportMap = {
        separation_id: 'Id',
        staff: 'Operador',
        status: 'Status',
    };

    const onGenerateSeparations = async () => {
        await generateSeparations.mutateAsync({}, {
            onSuccess: () => {
                setSeparationGenerationStatus('success');
                setTimeout(() => {
                    setSeparationGenerationStatus(null);
                    setSeparationModalVisible(false);
                }, 2000);
            },
            onError: () => {
                setSeparationGenerationStatus('error');
            },
        });
    };

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    gap: { xs: '16px', md: '0px' },
                    justifyContent: 'space-between',
                    marginBottom: '16px',
                }}
            >
                <div>
                    <Typography variant={'h3'} sx={{ fontSize: '28px', fontWeight: 'bold' }}>
                        {'Separações'}
                    </Typography>
                    <button
                        style={{
                            backgroundColor: 'transparent',
                            border: 'none',
                            color: '#bdbdbd',
                            cursor: 'pointer',
                        }}
                        type={'button'}
                        onClick={isFetching ? null : refetch}
                    >
                        {isFetching
                            ? 'Atualizando...'
                            : `Atualizado em ${lastFetchedTime
                                ? lastFetchedTime.toLocaleString('pt-BR', {
                                    hour: '2-digit',
                                    minute: '2-digit',
                                })
                                : '-'}`
                        }
                    </button>
                </div>
                <PinheiroButton
                    variant={'contained'}
                    startIcon={<Download />}
                    sx={{ 'backgroundColor': '#000000', '&:hover': { backgroundColor: '#f07d00' } }}
                    onClick={() => {
                        setSeparationModalVisible(true);
                        setSeparationGenerationStatus(null);
                    }}
                >
                    {'Gerar Separações'}
                </PinheiroButton>
                <PinheiroButton
                    variant={'contained'}
                    startIcon={<Download />}
                    onClick={() => downloadCSV(separations, exportMap)}
                    sx={{
                        'backgroundColor': '#000000',
                        '&:hover': { backgroundColor: '#f07d00' },
                    }}
                >
                    {'Exportar'}
                </PinheiroButton>
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-end',
                    flexDirection: { xs: 'column', md: 'row' },
                }}
            >
                <Box
                    sx={{
                        width: '100%',
                        display: 'flex',
                        gap: '16px',
                        flexDirection: { xs: 'column', md: 'row' },
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            gap: '32px',
                            flexDirection: { xs: 'column', md: 'row' },
                        }}
                    >
                        <PinheiroFormControl sx={{ width: { xs: '100%', md: '250px' } }}>
                            <Autocomplete
                                id={'period-label'}
                                onChange={(event, newValue) => setFilterDate(newValue)}
                                value={filterDate}
                                options={periodOptions}
                                getOptionLabel={(option) => option.label}
                                renderInput={(params) => (
                                    <PinheiroTextField
                                        {...params}
                                        label={'Período'}
                                        variant={'outlined'}
                                        size={'small'}
                                    />
                                )}
                                required
                            />
                        </PinheiroFormControl>
                        <PinheiroFormControl sx={{ width: { xs: '100%', md: '250px' } }}>
                            <Autocomplete
                                id={'separator-label'}
                                onChange={(event, newValue) => setSelectedUser(newValue)}
                                value={selectedUser}
                                options={usersOptions}
                                isLoading={isFetchingUsers}
                                getOptionLabel={(option) => option.label}
                                renderInput={(params) => (
                                    <PinheiroTextField
                                        {...params}
                                        label={'Separador'}
                                        variant={'outlined'}
                                        size={'small'}
                                    />
                                )}
                                required
                            />
                        </PinheiroFormControl>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={hideFuture}
                                    onChange={() => setHideFuture(!hideFuture)}
                                />
                            }
                            label={'Ocultar separações futuras'}
                        />
                    </Box>
                </Box>
            </Box>
            <DataTable
                data={separations}
                striped
                columns={columns}
                progressPending={isLoading}
                progressComponent={<Spinner />}
                noDataComponent={'Nenhum dado cadastrado'}
                pagination
                paginationServer
                paginationPerPage={20}
                paginationTotalRows={pagination?.total_data}
                paginationComponentOptions={paginationComponentOptions}
                onChangePage={setPage}
            />
            <Snackbar
                open={alert.status}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                onClose={() => setAlert({ status: false, type: alert.type, message: alert.message })
                }
            >
                <Alert
                    sx={{ width: '100%' }}
                    onClose={() => setAlert({ status: false, type: alert.type, message: alert.message })
                    }
                    severity={alert.type}
                >
                    {alert.message}
                </Alert>
            </Snackbar>
            <SeparationDrawer
                visible={visible}
                onClose={() => setVisible(false)}
                separationId={separationId}
            />
            <Modal
                open={separationModalVisible}
                onClose={() => {
                    setSeparationModalVisible(false);
                    setSeparationGenerationStatus(null);
                }}
                aria-labelledby={'modal-modal-title'}
                aria-describedby={'modal-modal-description'}
            >
                <Box
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        backgroundColor: 'white',
                        padding: '16px',
                        boxShadow: 24,
                        borderRadius: '8px',
                        p: 4,
                    }}
                >
                    {!separationGenerationStatus && !generateSeparations.isLoading && (
                        <>
                            <Typography id={'modal-modal-title'} variant={'h6'} component={'h2'}>
                                {'Tem certeza que deseja gerar as separações?'}
                            </Typography>
                            <Typography id={'modal-modal-description'} sx={{ mt: 2 }}>
                                {'Este processo pode demorar alguns minutos.'}
                            </Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2, gap: '16px' }}>
                                <PinheiroButton onClick={() => setSeparationModalVisible(false)}>
                                    {'Cancelar'}
                                </PinheiroButton>
                                <PinheiroButton onClick={() => onGenerateSeparations()}>
                                    {'Confirmar'}
                                </PinheiroButton>
                            </Box>
                        </>
                    )}
                    {!separationGenerationStatus && generateSeparations.isLoading && (
                        <Spinner />
                    )}
                    {'success' === separationGenerationStatus && (
                        <>
                            <Typography id={'modal-modal-title'} variant={'h6'} component={'h2'}>
                                {'Separações geradas com sucesso!'}
                            </Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                                <PinheiroButton onClick={() => setSeparationModalVisible(false)}>
                                    {'Fechar'}
                                </PinheiroButton>
                            </Box>
                        </>
                    )}
                    {'error' === separationGenerationStatus && (
                        <>
                            <Typography id={'modal-modal-title'} variant={'h6'} component={'h2'}>
                                {'Ocorreu um erro ao gerar as separações!'}
                            </Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                                <PinheiroButton onClick={() => setSeparationModalVisible(false)}>
                                    {'Fechar'}
                                </PinheiroButton>
                            </Box>
                        </>
                    )}
                </Box>
            </Modal>
        </>
    );
};

export default Separations;
