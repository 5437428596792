/* eslint-disable no-case-declarations */
import React, { useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Download } from '@mui/icons-material';
import {
    Chip,
    Typography,
    Box,
    Snackbar,
    Alert,
} from '@mui/material';
import {
    deepPurple, pink, teal,
} from '@mui/material/colors';
import {
    PinheiroButton,
    PinheiroFormControl,

} from 'components/PinheiroTheme';
import Spinner from 'components/Spinner';
import { useOrders } from 'hooks/useApi';
import Select from 'react-select';

const periodOptions = [
    { value: 'all', label: 'Todos' },
    { value: 'this-month', label: 'Este mês' },
    { value: 'last-month', label: 'Mês passado' },
    { value: 'next-month', label: 'Próximo mês' },
    { value: 'this-week', label: 'Esta semana' },
    { value: 'last-week', label: 'Semana passada' },
    { value: 'next-week', label: 'Próxima semana' },
];

const Orders = () => {
    const [filterDate, setFilterDate] = useState(periodOptions[0]);
    const [alert, setAlert] = useState({});
    const [page, setPage] = useState(1);

    const { startDate, endDate } = useMemo(() => {
        if (!filterDate) {
            return {};
        }

        const today = new Date();

        switch (filterDate.value) {
            case 'this-month':
                const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
                const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

                return {
                    startDate: firstDay,
                    endDate: lastDay,
                };
            case 'last-month':
                const firstDayLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
                const lastDayLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);

                return {
                    startDate: firstDayLastMonth,
                    endDate: lastDayLastMonth,
                };
            case 'next-month':
                const firstDayNextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1);
                const lastDayNextMonth = new Date(today.getFullYear(), today.getMonth() + 2, 0);

                return {
                    startDate: firstDayNextMonth,
                    endDate: lastDayNextMonth,
                };
            case 'this-week':
                const firstDayThisWeek = new Date(today.setDate(today.getDate() - today.getDay()));
                const lastDayThisWeek = new Date(today.setDate(today.getDate() - today.getDay() + 6));

                return {
                    startDate: firstDayThisWeek,
                    endDate: lastDayThisWeek,
                };
            case 'last-week':
                const firstDayLastWeek = new Date(today.setDate(today.getDate() - today.getDay() - 7));
                const lastDayLastWeek = new Date(today.setDate(today.getDate() - today.getDay() - 1));

                return {
                    startDate: firstDayLastWeek,
                    endDate: lastDayLastWeek,
                };
            case 'next-week':
                const firstDayNextWeek = new Date(today.setDate(today.getDate() - today.getDay() + 7));
                const lastDayNextWeek = new Date(today.setDate(today.getDate() - today.getDay() + 13));

                return {
                    startDate: firstDayNextWeek,
                    endDate: lastDayNextWeek,
                };
            default:
                return {};
        }
    }, [filterDate]);

    const { data, isFetching } = useOrders(page, startDate, endDate);

    const {
        data: orders,
        pagination,
    } = data || {};

    const columns = [
        {
            name: 'Id',
            width: '70px',
            center: true,
            selector: (row) => row.order_id,
            hide: 'sm',
        },
        {
            name: 'Equipe',
            selector: (row) => (0 < row.orders.length ? row.orders[0].staff : 'Não atribuído'),
        },
        {
            name: 'QTD. Produtos',
            maxWidth: '120px',
            center: true,
            selector: (row) => (row.products ? row.products.length : '0'),
        },
        {
            name: 'Data',
            maxWidth: '150px',
            center: true,
            selector: (row) => row.date.split('-').reverse()
                .join('/'),
            hide: 'sm',
        },
        {
            name: 'Status',
            maxWidth: '200px',
            cell: (row) => (
                <Chip
                    className={row.status
                        .toLowerCase()
                        .replaceAll(' ', '')
                        .replaceAll('ã', 'a')
                        .replaceAll('ç', 'c')}
                    sx={{
                        'backgroundColor': teal[500],
                        'fontSize': '12px',
                        'color': '#FFFFFF',
                        '&.emcarregamento': {
                            backgroundColor: deepPurple[500],
                        },
                        '&.aguardandoliberacao': {
                            backgroundColor: pink[500],
                        },
                    }}
                    size={'small'}
                    label={row.status}
                />
            ),
            center: true,
        },
    ];

    const paginationComponentOptions = {
        noRowsPerPage: true,
        rangeSeparatorText: 'de',
    };

    return (
        <>
            <Box
                component={'div'}
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    gap: { xs: '16px', md: '0px' },
                    justifyContent: 'space-between',
                    marginBottom: '16px',
                }}
            >
                <Typography variant={'h3'} sx={{ fontSize: '28px', fontWeight: 'bold' }}>
                    {'Pedidos'}
                </Typography>
                <PinheiroButton variant={'contained'} startIcon={<Download />}>
                    {'Exportar'}
                </PinheiroButton>
            </Box>
            <Box
                component={'div'}
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-end',
                    flexDirection: { xs: 'column', md: 'row' },
                }}
            >
                <Box
                    component={'div'}
                    sx={{
                        width: '100%',
                        display: 'flex',
                        gap: '16px',
                        flexDirection: { xs: 'column', md: 'row' },
                    }}
                >
                    <Box component={'div'}>
                        <PinheiroFormControl sx={{ width: { xs: '100%', md: '250px' } }}>
                            <Select
                                id={'period-label'}
                                onChange={setFilterDate}
                                value={filterDate}
                                options={periodOptions}
                                placeholder={'Período'}
                                required
                            />
                        </PinheiroFormControl>
                    </Box>
                </Box>
            </Box>
            <DataTable
                data={orders}
                striped
                columns={columns}
                progressPending={isFetching}
                progressComponent={<Spinner />}
                noDataComponent={'Nenhum dado cadastrado'}
                pagination
                paginationServer
                paginationPerPage={20}
                paginationTotalRows={pagination?.total_data}
                paginationComponentOptions={paginationComponentOptions}
                onChangePage={setPage}
            />
            <Snackbar
                open={alert.status}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                onClose={() => setAlert({ status: false, type: alert.type, message: alert.message })
                }
            >
                <Alert
                    sx={{ width: '100%' }}
                    onClose={() => setAlert({ status: false, type: alert.type, message: alert.message })
                    }
                    severity={alert.type}
                >
                    {alert.message}
                </Alert>
            </Snackbar>
        </>
    );
};

export default Orders;
