/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { Download } from '@mui/icons-material';
import {
    Typography,
    Box,
} from '@mui/material';
import DataTable from 'react-data-table-component';
import {
    PinheiroButton,
} from 'components/PinheiroTheme';
import { useProducts } from 'hooks/useApi';
import Spinner from 'components/Spinner';
import { downloadCSV } from 'utils/download';

const Produtcs = () => {
    const [page, setPage] = useState(1);

    const { data: productsData, isFetching } = useProducts(page);
    const { data, pagination } = productsData || {};

    const columns = [
        {
            name: 'ID',
            width: '70px',
            center: true,
            selector: (row) => row.product_id,
            hide: 'sm',
        },
        {
            name: 'Nome',
            selector: (row) => row.label,
        },
        {
            name: 'Endereço',
            maxWidth: '150px',
            selector: (row) => row.address,
            hide: 'sm',
        },
        {
            name: 'Largura',
            maxWidth: '100px',
            center: true,
            selector: (row) => row.width,
            hide: 'sm',
        },
        {
            name: 'Altura',
            maxWidth: '100px',
            center: true,
            selector: (row) => row.height,
            hide: 'sm',
        },
        {
            name: 'Peso',
            maxWidth: '100px',
            center: true,
            selector: (row) => row.weight,
            hide: 'sm',
        },
    ];

    const exportMap = {
        product_id: 'ID',
        label: 'Nome',
        height: 'Altura',
        width: 'Largura',
        weight: 'Peso',
        addresses: 'Endereço',
        created_at: 'Criado em',
        updated_at: 'Atualizado em',
    };

    const paginationComponentOptions = {
        noRowsPerPage: true,
        rangeSeparatorText: 'de',
    };

    return (
        <>
            <Box
                component={'div'}
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    gap: { xs: '16px', md: '0px' },
                    justifyContent: 'space-between',
                    marginBottom: '16px',
                }}
            >
                <Typography variant={'h3'} sx={{ fontSize: '28px', fontWeight: 'bold' }}>
                    {'Produtos'}
                </Typography>
                <PinheiroButton
                    variant={'contained'}
                    startIcon={<Download />}
                    sx={{ 'backgroundColor': '#000000', '&:hover': { backgroundColor: '#f07d00' } }}
                    onClick={() => downloadCSV(data, exportMap)}
                >
                    {'Exportar'}
                </PinheiroButton>
            </Box>
            <DataTable
                data={data}
                striped
                columns={columns}
                progressPending={isFetching}
                progressComponent={<Spinner />}
                noDataComponent={'Nenhum dado cadastrado'}
                pagination
                paginationServer
                paginationPerPage={20}
                paginationTotalRows={pagination?.total_data}
                paginationComponentOptions={paginationComponentOptions}
                onChangePage={setPage}
            />
        </>
    );
};

export default Produtcs;
