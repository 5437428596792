import React, { useContext, useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
    Box,
    Checkbox,
    Stack,
    FormControl,
    FormControlLabel,
    IconButton,
    InputAdornment,
    Typography,
    FormHelperText,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { Link, useNavigate } from 'react-router-dom';
import {
    PinheiroButton,
    PinheiroInputLabel,
    PinheiroOutlinedInput,
} from 'components/PinheiroTheme';
import { AuthContext } from 'contexts/AuthContext';
import { useForm } from 'react-hook-form';
import Loading from 'components/Loading';

const Login = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const {
        signIn,
    } = useContext(AuthContext);

    const { register, handleSubmit, formState: { error } } = useForm();

    const onSubmit = handleSubmit(async (data) => {
        setLoading(true);

        try {
            await signIn(data);
            navigate('/dashboard');
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    });
    return (
        <>
            <Typography
                variant={'h1'}
                sx={{ fontSize: '32px', fontWeight: 'bold', mb: 2 }}
            >
                {'Bem vindo!'}
            </Typography>
            <Typography variant={'p'} sx={{ color: grey[600] }}>
                {'Use seu email e senha para fazer login'}
            </Typography>
            <Box sx={{ mt: 4 }}>
                <Loading
                    isLoading={loading}
                >
                    <form
                        onSubmit={onSubmit}
                    >
                        <FormControl variant={'outlined'} required fullWidth>
                            <PinheiroInputLabel htmlFor={'outlined-adornment-password'}>
                                {'Usuário'}
                            </PinheiroInputLabel>
                            <PinheiroOutlinedInput
                                sx={{ mb: 3 }}
                                placeholder={'josedasilva'}
                                error={error?.username}
                                {...register('username', { required: true })}
                            />
                            <FormHelperText
                                error={error?.username}
                            >
                                {'required' === error?.username?.type && 'Campo obrigatório'}
                            </FormHelperText>
                        </FormControl>
                        <FormControl variant={'outlined'} required fullWidth>
                            <PinheiroInputLabel htmlFor={'outlined-adornment-password'}>
                                {'Senha'}
                            </PinheiroInputLabel>
                            <PinheiroOutlinedInput
                                label={'Password'}
                                type={showPassword ? 'text' : 'password'}
                                error={error?.password}
                                {...register('password', { required: true })}
                                endAdornment={
                                    <InputAdornment position={'end'}>
                                        <IconButton
                                            aria-label={'toggle password visibility'}
                                            onClick={() => setShowPassword(!showPassword)}
                                            onMouseDown={() => setShowPassword(!showPassword)}
                                            edge={'end'}
                                        >
                                            {showPassword
                                                ? (
                                                    <VisibilityOff />
                                                )
                                                : (
                                                    <Visibility />
                                                )
                                            }
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            <FormHelperText
                                error={error?.password}
                            >
                                {'required' === error?.password?.type && 'Campo obrigatório'}
                            </FormHelperText>
                        </FormControl>
                        <Stack
                            direction={'row'}
                            sx={{
                                mt: 2,
                                alignItems: 'center',
                                justifyContent: 'space-between',
                            }}
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        sx={{
                                            'me': '10px',
                                            'color': '#f07d00',
                                            '&.Mui-checked': { color: '#f07d00' },
                                        }}
                                        id={'remember-login'}
                                        {...register('keep_logged')}
                                    />
                                }
                                label={'Me manter logado'}
                            />
                            <Link
                                to={'/forgot-password'}
                                style={{ color: '#f07d00', textDecoration: 'none' }}
                            >
                                {'Esqueceu sua senha?'}
                            </Link>
                        </Stack>
                        <PinheiroButton
                            sx={{ width: '100%', mt: 2 }}
                            variant={'contained'}
                            size={'large'}
                            type={'submit'}
                        >
                            {'Entrar'}
                        </PinheiroButton>
                    </form>
                </Loading>
            </Box>
        </>
    );
};

export default Login;
