import React from 'react';
import AuthProvider from 'contexts/AuthContext';
import Ways from './routes';
import { queryClient } from 'services/queryClient';
import { QueryClientProvider } from '@tanstack/react-query';

const App = () => {
    return (
        <QueryClientProvider client={queryClient}>
            <AuthProvider>
                <Ways />
            </AuthProvider>
        </QueryClientProvider>
    );
};

export default App;
