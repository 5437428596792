import React, { useContext } from 'react';
import {
    Chip, Typography,
} from '@mui/material';
import DataTable from 'react-data-table-component';
import Spinner from 'components/Spinner';
import { useSeparations } from 'hooks/useApi';
import { AuthContext } from 'contexts/AuthContext';
import { capitalize } from 'utils/text';
import { separationStatuses } from 'utils/enums';

const Home = () => {
    const { user } = useContext(AuthContext);

    const { data, isLoading } = useSeparations(false);

    const columns = [
        {
            name: 'Id',
            width: '70px',
            center: true,
            selector: (row) => row.separation_id,
            hide: 'sm',
        },
        {
            name: 'Operador',
            sortable: true,
            selector: (row) => row.user?.first_name
                ? `${row.user.first_name || ''} ${row.user.last_name || ''}`
                : '-',
        },
        {
            name: 'Início',
            selector: (row) => row.start_date
                ? new Date(row.start_date).toLocaleString('pt-BR', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                })
                : '-',
        },
        {
            name: 'Fim',
            selector: (row) => row.end_date
                ? new Date(row.end_date).toLocaleString('pt-BR', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                })
                : '-',
        },
        {
            name: 'Status',
            cell: (row) => {
                const { status } = row;

                const { label, color, backgroundColor } = separationStatuses.get(status) || {};

                return (
                    (
                        <Chip
                            sx={{
                                backgroundColor,
                                fontSize: '12px',
                                color,
                            }}
                            size={'small'}
                            label={label}
                        />
                    )
                );
            },
            center: true,
        },
    ];

    return (
        <>
            <Typography
                variant={'h6'}
                sx={{
                    fontSize: '16px',
                    fontWeight: 'bold',
                    marginBottom: '16px',
                }}
            >
                {'Bem-vindo(a) '}
                <Typography
                    variant={'div'}
                    sx={{
                        fontSize: '28px',
                        fontWeight: 'bold',
                        display: 'block',
                        color: '#f07d00',
                    }}
                >
                    {user && `${capitalize(user?.first_name)} ${capitalize(user?.last_name)}`}
                </Typography>
            </Typography>
            <Typography variant={'h3'} sx={{ fontSize: '28px', fontWeight: 'bold' }}>
                {'Atividades'}
            </Typography>
            <DataTable
                data={data?.data}
                striped
                columns={columns}
                progressPending={isLoading}
                progressComponent={<Spinner />}
                noDataComponent={'Nenhum dado cadastrado'}
            />
        </>
    );
};

export default Home;
