import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import LayoutAuth from '../layouts/Auth';
import Login from '../pages/Login';
import ForgotPassword from '../pages/ForgotPassword';
import ChangePassword from '../pages/ChangePassword';
import LayoutDashboard from '../layouts/Dashboard';
import Home from '../pages/Home';
import Orders from '../pages/Loads';
import Separations from '../pages/Separations';
import Employers from '../pages/Employers';
import Jobs from '../pages/Jobs';
import Produtcs from '../pages/Products';

const Ways = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path={'/'} element={<LayoutAuth />}>
                    <Route index element={<Login />} />
                    <Route path={'/forgot-password'} element={<ForgotPassword />} />
                    <Route path={'/change-password'} element={<ChangePassword />} />
                </Route>
                <Route path={'/dashboard'} element={<LayoutDashboard />}>
                    <Route index element={<Home />} />
                    <Route path={'/dashboard/orders'} element={<Orders />} />
                    <Route path={'/dashboard/separations'} element={<Separations />} />
                    <Route path={'/dashboard/employers'} element={<Employers />} />
                    <Route path={'/dashboard/jobs'} element={<Jobs />} />
                    <Route path={'/dashboard/products'} element={<Produtcs />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
};

export default Ways;
