import { grey, orange, pink, teal } from '@mui/material/colors';

export const separationStatuses = new Map([
    ['X', { label: 'Descartada', backgroundColor: grey[500], color: 'white' }],
    ['G', { label: 'Gerando', backgroundColor: orange[500], color: 'white' }],
    ['P', { label: 'Pendente', backgroundColor: pink[500], color: 'white' }],
    ['O', { label: 'Em separação', backgroundColor: teal[500], color: 'white' }],
    ['I', { label: 'Interrompida', backgroundColor: 'red', color: 'white' }],
    ['S', { label: 'Carregando', backgroundColor: 'blue', color: 'white' }],
    ['C', { label: 'Finalizada', backgroundColor: 'green', color: 'white' }],
]);
