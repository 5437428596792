import { Button, FormControl, IconButton, InputLabel, ListItemButton, OutlinedInput, TextField, styled } from '@mui/material';
import { Link } from 'react-router-dom';

export const PinheiroTextField = styled(TextField)({
    '& label': {
        fontWeight: 'bold',
    },
    '& label.Mui-focused': {
        color: '#008136',
        transitionDuration: '300ms',
    },
    '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
            transitionDuration: '300ms',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#f07d00',
            borderWidth: '1px',
        },
    },
});

export const PinheiroInputLabel = styled(InputLabel)({
    '&': {
        fontWeight: 'bold',
    },
    '&:hover': {
        transitionDuration: '300ms',
    },
    '&.Mui-focused': {
        color: '#008136',
    },
});

export const PinheiroOutlinedInput = styled(OutlinedInput)({
    '&.MuiOutlinedInput-root': {
        '&:hover fieldset': {
            transitionDuration: '300ms',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#f07d00',
            borderWidth: '1px',
        },
    },
});

export const PinheiroButton = styled(Button)({
    '&': {
        backgroundColor: 'black',
        lineHeight: '40px',
        transitionDuration: '300ms',
    },
    '&:hover': {
        backgroundColor: '#f07d00',
    },
});

export const PinheiroIconButton = styled(IconButton)({

    '&:hover': {
        backgroundColor: '#ffd9af',
    },
    '&.disabled': {
        backgroundColor: '#999999',
        cursor: 'not-allowed',
    },
});

const StyledListItemButton = styled(ListItemButton)({
    'color': '#FFFFFF',
    'transition': 'all 300ms ease',
    'position': 'relative',
    '&:hover': {
        color: '#f07d00',
    },
    '&.active': {
        color: '#f07d00',
    },
    '&.active svg': {
        color: '#f07d00',
    },
    '&::after': {
        content: '""',
        width: '100%',
        height: '100%',
        backgroundImage: 'linear-gradient(90deg, #111111, transparent)',
        position: 'absolute',
        top: 0,
        right: 0,
        opacity: 0,
        transition: 'all 300ms ease',
    },
    '&:hover::after': {
        opacity: 1,
    },
    '& .MuiSvgIcon-root': {
        color: '#FFFFFF',
        transition: 'all 300ms ease',
        position: 'relative',
        zIndex: 1,
    },
    '&:hover .MuiSvgIcon-root': {
        color: '#f07d00',
    },
    '& .MuiTypography-root': {
        position: 'relative',
        zIndex: 1,
    },
});

export const PinheiroListItemButton = ({ href, ...props }) => {
    return (
        <Link
            to={href}
            style={{
                textDecoration: 'none',
            }}
        >
            <StyledListItemButton {...props} />
        </Link>
    );
};

export const PinheiroFormControl = styled(FormControl)({
    '&.MuiFormControl-root': {
        '&:hover fieldset': {
            transitionDuration: '300ms',
        },
        '& .Mui-focused fieldset': {
            borderColor: '#f07d00',
            borderWidth: '1px',
        },
    },
});
