import React from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
    Box,
    FormControl,
    IconButton,
    InputAdornment,
    Typography,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { PinheiroButton, PinheiroInputLabel, PinheiroOutlinedInput } from 'components/PinheiroTheme';

const ChangePassword = () => {
    const [showPassword, setShowPassword] = React.useState(false);

    return (
        <>
            <Typography variant={'h1'} sx={{ fontSize: '32px', fontWeight: 'bold', mb: 2 }}>
                {'Escolha uma nova senha'}
            </Typography>
            <Typography variant={'p'} sx={{ color: grey[600] }}>
                {'Hora de trocar a senha!'}
                <br />
                {'Diga uma nova senha que deseja para sua conta'}
            </Typography>
            <Box component={'form'} sx={{ mt: 4 }}>
                <FormControl variant={'outlined'} required fullWidth sx={{ mb: 2 }}>
                    <PinheiroInputLabel htmlFor={'outlined-adornment-password'}>
                        {'Senha'}
                    </PinheiroInputLabel>
                    <PinheiroOutlinedInput
                        label={'Senha'}
                        type={showPassword ? 'text' : 'password'}
                        endAdornment={
                            <InputAdornment position={'end'}>
                                <IconButton
                                    aria-label={'toggle password visibility'}
                                    onClick={() => setShowPassword(!showPassword)}
                                    onMouseDown={() => setShowPassword(!showPassword)}
                                    edge={'end'}
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
                <FormControl variant={'outlined'} required fullWidth sx={{ mb: 2 }}>
                    <PinheiroInputLabel htmlFor={'outlined-adornment-password'}>
                        {'Confirmar Senha'}
                    </PinheiroInputLabel>
                    <PinheiroOutlinedInput
                        label={'Confirmar Senha'}
                        type={showPassword ? 'text' : 'password'}
                        endAdornment={
                            <InputAdornment position={'end'}>
                                <IconButton
                                    aria-label={'toggle password visibility'}
                                    onClick={() => setShowPassword(!showPassword)}
                                    onMouseDown={() => setShowPassword(!showPassword)}
                                    edge={'end'}
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
                <PinheiroButton sx={{ width: '100%' }} variant={'contained'} size={'large'}>
                    {'Enviar'}
                </PinheiroButton>
            </Box>
        </>
    );
};

export default ChangePassword;
