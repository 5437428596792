import React, { useMemo, useState } from 'react';
import { Download } from '@mui/icons-material';
import {
    Typography,
    Box,
    Alert,
    Snackbar,
    Autocomplete,
    Skeleton,
} from '@mui/material';
import DataTable from 'react-data-table-component';
import {
    PinheiroButton, PinheiroTextField,
} from 'components/PinheiroTheme';
import Spinner from 'components/Spinner';
import { useJobs, usePatchStore, useStores } from 'hooks/useApi';
import { downloadCSV } from 'utils/download';

const JobSelect = ({ value, onChange }) => {
    const { data: jobsData, isFetching: isFetchingJobs } = useJobs();
    const { data } = jobsData || {};

    const options = useMemo(() => {
        if (!data) {
            return [];
        }

        return data.map((job) => ({
            title: job.label,
            value: job.job_id,
        }));
    }, [data]);

    return (
        <Box sx={{ width: '100%', paddingTop: '8px', paddingBottom: '8px' }} >
            {isFetchingJobs
                ? (
                    <Skeleton
                        variant={'rounded'}
                        height={56}
                    />
                )
                : (
                    <Autocomplete
                        value={value}
                        onChange={(event, newValue) => {
                            if ('string' === typeof newValue) {
                                onChange({
                                    title: newValue,
                                });

                                return;
                            }

                            if (newValue && newValue.inputValue) {
                                onChange({
                                    title: newValue.inputValue,
                                });

                                return;
                            }

                            if (!newValue) {
                                onChange({
                                    title: '',
                                });
                            }

                            onChange(newValue);
                        }}
                        selectOnFocus
                        options={options}
                        getOptionLabel={(option) => {
                            if ('string' === typeof option) {
                                return option;
                            }

                            if (option.inputValue) {
                                return option.inputValue;
                            }

                            return option.title;
                        }}
                        renderOption={(props, option) => <li {...props}>{option.title}</li>}
                        sx={{ width: '100%' }}
                        renderInput={(params) => (
                            <PinheiroTextField {...params} />
                        )}
                    />
                )}
        </Box>
    );
};

const Jobs = () => {
    const [alert, setAlert] = useState({});
    const [page, setPage] = useState(1);

    const { data: storesData, isFetching: isFetchingStores } = useStores(page);
    const { data: jobsData } = useJobs();
    const updateStore = usePatchStore();

    const {
        data: storesList,
        pagination: storesPagination,
    } = storesData || {};

    const {
        data: jobs,
    } = jobsData || {};

    const onJobChange = async (store_id, job) => {
        await updateStore.mutateAsync({
            storeId: store_id,
            data: {
                job_id: job.value,
            },
        });

        setAlert({
            message: 'Job atualizado com sucesso!',
            severity: 'success',
        });
    };

    const columns = [
        {
            name: 'ID',
            width: '70px',
            center: true,
            selector: (row) => row.store_id,
            hide: 'sm',
        },
        {
            name: 'Job',
            selector: (row) => row.job,
            cell: (row) => (
                <JobSelect
                    value={row.job}
                    onChange={(value) => onJobChange(row.store_id, value)}
                />
            ),
        },
        {
            name: 'Loja',
            selector: (row) => row.label,
        },
        {
            name: 'Endereço',
            selector: (row) => row.address,
            hide: 'sm',
        },
    ];

    const stores = useMemo(() => {
        if (!storesList || !jobs) {
            return [];
        }

        return storesList.map((store) => {
            const job = jobs.find((job) => job.job_id === store.job_id);
            return {
                ...store,
                address: `${store.street}, ${store.city} - ${store.state}`,
                job: job?.label,
                job_id: job?.job_id,
            };
        });
    }, [storesList, jobs]);

    const exportMap = {
        store_id: 'ID',
        job: 'Job',
        label: 'Loja',
        address: 'Endereço',
    };

    return (
        <>
            <Box
                component={'div'}
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    gap: { xs: '16px', md: '0px' },
                    justifyContent: 'space-between',
                    marginBottom: '16px',
                }}
            >
                <Typography variant={'h3'} sx={{ fontSize: '28px', fontWeight: 'bold' }}>
                    {'Jobs'}
                </Typography>
                <PinheiroButton
                    variant={'contained'}
                    startIcon={<Download />}
                    sx={{ 'backgroundColor': '#000000', '&:hover': { backgroundColor: '#f07d00' } }}
                    onClick={() => downloadCSV(stores, exportMap)}
                >
                    {'Exportar'}
                </PinheiroButton>
            </Box>
            <DataTable
                data={stores}
                columns={columns}
                progressPending={isFetchingStores}
                progressComponent={<Spinner />}
                noDataComponent={'Nenhum dado cadastrado'}
                pagination
                paginationServer
                paginationPerPage={20}
                paginationTotalRows={storesPagination?.total_data}
                onChangePage={setPage}
                paginationComponentOptions={{
                    noRowsPerPage: true,
                    rangeSeparatorText: 'de',
                }}
            />
            <Snackbar
                open={alert.status}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                onClose={() => setAlert({ status: false, type: alert.type, message: alert.message })}
            >
                <Alert
                    sx={{ width: '100%' }}
                    onClose={() => setAlert({ status: false, type: alert.type, message: alert.message })}
                    severity={alert.type}
                >
                    {alert.message}
                </Alert>
            </Snackbar>
        </>
    );
};

export default Jobs;
