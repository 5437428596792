import React from 'react';
import { Box, Typography } from '@mui/material';
import { grey, red } from '@mui/material/colors';
import { PinheiroButton, PinheiroTextField } from 'components/PinheiroTheme';

const ForgotPassword = () => (
    <>
        <Typography variant={'h1'} sx={{ fontSize: '32px', fontWeight: 'bold', mb: 2 }}>
            {'Esqueceu sua senha?'}
        </Typography>
        <Typography variant={'p'} sx={{ color: grey[600] }}>
            {'Nos diga seu email!'}
            <br />
            {'Vamos lhe enviar um link para trocar sua senha'}
        </Typography>
        <Box component={'form'} sx={{ mt: 4 }}>
            <PinheiroTextField
                sx={{ mb: 2, borderColor: red[400] }}
                type={'text'}
                label={'Email:'}
                variant={'outlined'}
                placeholder={'email@mail.com'}
                required
                fullWidth
            />
            <PinheiroButton sx={{ width: '100%' }} variant={'contained'} size={'large'}>
                {'Enviar'}
            </PinheiroButton>
        </Box>
    </>
);

export default ForgotPassword;
