import {
    Box,
    Container,
    Grid,
} from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router-dom';

import logo from './assets/logoPinheiro.png';
import pinbg from './assets/pinBg.png';
import bg from './assets/bg.jpg';

const LayoutAuth = () => (
    <Container
        sx={{
            height: '100vh',
            display: 'flex',
            overflow: 'auto',
            alignItems: 'center',
            padding: { xs: '50px 16px 50px 16px' },
        }}
    >
        <Grid container>
            <Grid item sm={2} sx={{ display: { lg: 'none' } }} />
            <Grid item xs={12} sm={8} lg={4}>
                <Box
                    component={'img'}
                    src={logo}
                    alt={'bg'}
                    sx={{ width: '200px', display: { xs: 'block', lg: 'none' }, margin: '0 auto' }}
                />
                <Outlet />
            </Grid>
        </Grid>
        <Box
            sx={{
                width: { xs: '100%', md: '100%', lg: '60%' },
                height: '100vh',
                position: 'absolute',
                top: 0,
                right: 0,
                display: { xs: 'none', lg: 'flex' },
                justifyContent: 'center',
                alignItems: { sm: 'initial', lg: 'center' },
                zIndex: -1,
            }}
        >
            <Box
                component={'div'}
                sx={{
                    'width': '100%',
                    'height': { sm: '300px', lg: '100vh' },
                    'backgroundImage': `url(${bg})`,
                    'backgroundSize': 'cover',
                    'backdropFilter': 'blur(3px)',
                    'filter': 'grayscale(1) blur(3px)',
                    'position': 'absolute',
                    'top': 0,
                    'left': 0,
                    'zIndex': -1,
                    '&::after': {
                        content: '""',
                        width: '100%',
                        height: '80px',
                        backgroundColor: '#ffffff',
                        borderRadius: '50px 50px 0 0',
                        position: 'absolute',
                        bottom: '-1px',
                        left: 0,
                        zIndex: 1,
                        display: { lg: 'none' },
                    },
                }}
            />
            <Box
                component={'img'}
                src={logo}
                alt={'bg'}
                sx={{
                    height: '300px',
                    display: 'block',
                    marginLeft: { sm: 0, lg: '100px' },
                }}
            />
            <Box
                component={'img'}
                src={pinbg}
                alt={'pinbg'}
                sx={{
                    height: '100%',
                    display: { xs: 'none', md: 'none', lg: 'block' },
                    position: 'absolute',
                    top: 0,
                    left: '-6px',
                }}
            />
        </Box>
    </Container>
);

export default LayoutAuth;
