export const convertArrayOfObjectsToCSV = (data, keyTranslations) => {
    const headerKeys = Object.keys(keyTranslations || data[0] || {});

    const headers = headerKeys
        .map((key) => keyTranslations?.[key] || key)
        .join(',');

    const csvRows = data.map((row) => {
        const values = headerKeys.map((key) => {
            const cellValue = row[key] || '';
            const escapedValue = cellValue.toString().replace(/"/g, '\\"');

            return `"${escapedValue}"`;
        });

        return values.join(',');
    });

    csvRows.unshift(headers);

    return csvRows.join('\n');
};

export const downloadCSV = (array, keyTranslations) => {
    const link = document.createElement('a');
    let csv = convertArrayOfObjectsToCSV(array, keyTranslations);
    if (null == csv) {
        return;
    }

    const filename = 'export.csv';

    if (!csv.match(/^data:text\/csv/i)) {
        csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute('href', encodeURI(csv));
    link.setAttribute('download', filename);
    link.click();
};
