import React, { useState } from 'react';
import { Close, Download, Edit } from '@mui/icons-material';
import {
    Typography,
    Box,
    Snackbar,
    Alert,
    Drawer,
    Stack,
    Autocomplete,
} from '@mui/material';
import DataTable from 'react-data-table-component';
import { PinheiroButton, PinheiroIconButton, PinheiroTextField } from 'components/PinheiroTheme';
import { useCreateUser, useEditUser, useRoles, useUsers, useZones } from 'hooks/useApi';
import Spinner from 'components/Spinner';
import { capitalize } from 'utils/text';
import { Controller, useForm } from 'react-hook-form';
import { downloadCSV } from 'utils/download';

const Employers = () => {
    const [alert, setAlert] = useState({});
    const [page, setPage] = useState(1);
    const [visible, setVisible] = useState(false);
    const [mode, setMode] = useState('create');
    const [selectedUserId, setSelectedUserId] = useState();

    const { data: usersData, isFetching: isFetchingUsers } = useUsers(page);
    const { data: rolesData, isFetching: isFetchingRoles } = useRoles();
    const { data: zonesData, isFetching: isFetchingZones } = useZones();

    const createUser = useCreateUser();
    const editUser = useEditUser();

    const userForm = useForm({
        defaultValues: {
            first_name: '',
            last_name: '',
            username: '',
            email: '',
            role_id: '',
            zone_id: '',
            password: '',
        },
    });

    const handleSubmit = () => userForm.handleSubmit(async (data) => {
        try {
            if ('create' === mode) {
                await createUser.mutateAsync(data);
            } else {
                await editUser.mutateAsync({ ...data, user_id: selectedUserId });
            }
            setVisible(false);
            setAlert({
                status: true,
                type: 'success',
                message: `Operador ${'create' === mode ? 'criado' : 'editado'} com sucesso`,
            });
        } catch (error) {
            setAlert({
                status: true,
                type: 'error',
                message: error?.response?.data?.message || `Erro ao ${'create' === mode ? 'criar' : 'editar'} operador`,
            });
        }
    });

    const handleDrawerClose = () => {
        setVisible(false);
        userForm.reset({});
    };

    const {
        data: users,
        pagination: usersPagination,
    } = usersData || {};

    const columns = [
        {
            name: 'id',
            width: '70px',
            center: true,
            selector: (row) => row.user_id,
            hide: 'sm',
        },
        {
            name: 'Nome',
            selector: (row) => `${capitalize(row.first_name)} ${capitalize(row.last_name)}`,
        },
        {
            name: 'Email',
            selector: (row) => row.email,
        },
        {
            name: 'Cargo',
            selector: (row) => capitalize(row.role_description),
        },
        {
            name: 'Zona',
            selector: (row) => {
                const zone = zonesData?.data?.find((zone) => zone.zone_id === row.zone_id);
                return zone?.label;
            },
        },
        {
            name: 'Ações',
            width: '150px',
            center: true,
            cell: (row) => (
                <Stack direction={'row'} spacing={1}>
                    <PinheiroIconButton
                        size={'small'}
                        onClick={() => {
                            userForm.reset({
                                first_name: capitalize(row.first_name),
                                last_name: capitalize(row.last_name),
                                username: row.username,
                                email: row.email,
                                role_id: row.role_id,
                                zone_id: row.zone_id,
                            });
                            setVisible(true);
                            setMode('edit');
                            setSelectedUserId(row.user_id);
                        }}
                    >
                        <Edit />
                    </PinheiroIconButton>
                </Stack>
            ),
        },
    ];

    const exportMap = {
        user_id: 'ID',
        first_name: 'Nome',
        last_name: 'Sobrenome',
        email: 'Email',
        role_description: 'Cargo',
        zone_id: 'Zona',
    };

    return (
        <>
            <Box
                component={'div'}
                sx={{
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    gap: { xs: '16px', md: '0px' },
                    justifyContent: 'space-between',
                    marginBottom: '16px',
                }}
            >
                <Typography variant={'h3'} sx={{ fontSize: '28px', fontWeight: 'bold' }}>
                    {'Operadores'}
                </Typography>
                <Box
                    component={'div'}
                    sx={{
                        display: 'flex',
                        gap: '16px',
                        justifyContent: 'flex-end',
                        flexDirection: { xs: 'column', md: 'row' },
                    }}
                >
                    <PinheiroButton
                        variant={'contained'}
                        startIcon={<Download />}
                        onClick={() => downloadCSV(users, exportMap)}
                        sx={{
                            'backgroundColor': '#000000',
                            '&:hover': { backgroundColor: '#f07d00' },
                        }}
                    >
                        {'Exportar'}
                    </PinheiroButton>
                    <PinheiroButton
                        variant={'contained'}
                        onClick={() => {
                            userForm.reset({});
                            setVisible(true);
                            setMode('create');
                        }}
                        sx={{
                            'backgroundColor': '#000000',
                            '&:hover': { backgroundColor: '#f07d00' },
                        }}
                    >
                        {'Adicionar'}
                    </PinheiroButton>
                </Box>
            </Box>
            <DataTable
                data={users}
                striped
                columns={columns}
                progressPending={isFetchingUsers || isFetchingRoles || isFetchingZones}
                progressComponent={<Spinner />}
                noDataComponent={'Nenhum dado cadastrado'}
                pagination
                paginationServer
                paginationPerPage={20}
                paginationTotalRows={usersPagination?.total_data}
                onChangePage={setPage}
                paginationComponentOptions={{
                    noRowsPerPage: true,
                    rangeSeparatorText: 'de',
                }}
            />
            <Snackbar
                open={alert.status}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                onClose={() => setAlert({ status: false, type: alert.type, message: alert.message })
                }
            >
                <Alert
                    sx={{ width: '100%' }}
                    onClose={() => setAlert({ status: false, type: alert.type, message: alert.message })
                    }
                    severity={alert.type}
                >
                    {alert.message}
                </Alert>
            </Snackbar>

            <Drawer
                anchor={'right'}
                open={visible}
                onClose={handleDrawerClose}
            >
                <Box sx={{ width: { xs: '100%', md: '400px' }, padding: '16px' }}>
                    <Stack
                        sx={{ pb: 2 }}
                        direction={'row'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                    >
                        <Typography variant={'h5'} sx={{ fontWeight: 'bold' }}>
                            {`${'create' === mode ? 'Adicionar' : 'Editar'} operador`}
                        </Typography>
                        <PinheiroIconButton onClick={handleDrawerClose}>
                            <Close />
                        </PinheiroIconButton>
                    </Stack>
                    <form
                        onSubmit={handleSubmit()}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '16px',
                            }}
                        >
                            <PinheiroTextField
                                label={'Nome'}
                                fullWidth
                                required
                                {...userForm.register('first_name', {
                                    required: 'Campo obrigatório',
                                })}
                            />

                            <PinheiroTextField
                                label={'Sobrenome'}
                                fullWidth
                                required
                                {...userForm.register('last_name', {
                                    required: 'Campo obrigatório',
                                })}
                            />

                            <PinheiroTextField
                                label={'Usuário'}
                                fullWidth
                                required
                                {...userForm.register('username', {
                                    required: 'Campo obrigatório',
                                })}
                            />

                            <PinheiroTextField
                                label={'Email'}
                                fullWidth
                                {...userForm.register('email', {
                                    email: 'Email inválido',
                                })}
                            />

                            <PinheiroTextField
                                label={'Senha'}
                                type={'password'}
                                fullWidth
                                required
                                {...userForm.register('password', {
                                    required: 'Campo obrigatório',
                                })}
                            />

                            <Controller
                                name={'role_id'}
                                control={userForm.control}
                                rules={{ required: 'Campo obrigatório' }}
                                render={({ field }) => {
                                    const role = rolesData?.data?.find(
                                        (role) => role.role_id === field.value,
                                    );

                                    return (
                                        <Autocomplete
                                            options={rolesData?.data?.map((role) => ({
                                                value: role.role_id,
                                                label: capitalize(role.role_description),
                                            }))}
                                            value={role?.role_id}
                                            inputValue={capitalize(role?.role_description)}
                                            onChange={(event, value) => {
                                                field.onChange(value.value);
                                            }}
                                            getOptionLabel={(option) => option.label}
                                            renderInput={(params) => (
                                                <PinheiroTextField
                                                    {...params}
                                                    label={'Função'}
                                                    fullWidth
                                                    required
                                                />
                                            )}
                                        />
                                    );
                                }}
                            />

                            <Controller
                                name={'zone_id'}
                                control={userForm.control}
                                rules={{ required: 'Campo obrigatório' }}
                                render={({ field }) => {
                                    const zone = zonesData?.data?.find(
                                        (zone) => zone.zone_id === field.value,
                                    );

                                    return (
                                        <Autocomplete
                                            options={zonesData?.data?.map((zone) => ({
                                                value: zone.zone_id,
                                                label: capitalize(zone.label),
                                            }))}
                                            value={zone?.zone_id}
                                            inputValue={capitalize(zone?.label)}
                                            onChange={(event, value) => {
                                                field.onChange(value.value);
                                            }}
                                            getOptionLabel={(option) => option.label}
                                            renderInput={(params) => (
                                                <PinheiroTextField
                                                    {...params}
                                                    label={'Zona'}
                                                    fullWidth
                                                    required
                                                />
                                            )}
                                        />
                                    );
                                }}
                            />

                            <PinheiroButton
                                sx={{ width: '100%' }}
                                variant={'contained'}
                                type={'submit'}
                            >
                                {'Enviar'}
                            </PinheiroButton>
                        </Box>
                    </form>
                </Box>
            </Drawer>
        </>
    );
};

export default Employers;
