import './styles.scss';

const Loading = ({ isLoading, children }) => {
    return (
        <div className={'loading'}>
            {isLoading && (
                <div className={'loading__overlay'}>
                    <div className={'loading__spinner'}>
                        <div className={'loading__icon'}></div>
                    </div>
                </div>
            )}
            {children}
        </div>
    );
};

export default Loading;
