import { Box, Tooltip } from '@mui/material';
import { useState } from 'react';

const TableCell = ({ data, tooltip, canCopy }) => {
    const [clicked, setClicked] = useState(false);

    const Content = (
        <Box
            role={'button'}
            sx={{
                cursor: canCopy ? 'pointer' : 'default',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            }}
            onClick={() => {
                if (canCopy) {
                    navigator.clipboard.writeText(data);
                    setClicked(true);
                    setTimeout(() => setClicked(false), 1000);
                }
            }}
        >
            {data}
        </Box>
    );

    return (
        <Tooltip
            title={'Copiado!'}
            open={clicked}
            placement={'top'}
            arrow
        >
            {tooltip
                ? (
                    <Tooltip
                        title={tooltip}
                        placement={'top'}
                        enterDelay={500}
                        enterNextDelay={500}
                        arrow
                    >
                        {Content}
                    </Tooltip>
                )
                : (
                    Content
                )
            }
        </Tooltip>
    );
};

export default TableCell;
