import { Close } from '@mui/icons-material';
import { Box, Chip, Drawer, IconButton, Stack, Typography } from '@mui/material';
import Spinner from 'components/Spinner';
import TableCell from 'components/TableCell';
import { useSeparation } from 'hooks/useApi';
import React, { useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { separationStatuses } from 'utils/enums';

const SeparationDrawer = ({ visible, onClose, separationId }) => {
    const { data, isLoading } = useSeparation(separationId);
    const { data: separation } = data || {};

    const stores = useMemo(() => {
        const stores = new Set();

        separation?.boxes?.forEach((box) => {
            stores.add(box.store);
        });

        return Array.from(stores);
    }, [separation]);

    const status = separation && separationStatuses.get(separation?.status);

    const boxesColumns = [
        {
            name: 'id',
            width: '70px',
            center: true,
            selector: (row) => row.box_id,
            hide: 'sm',
        },
        {
            name: 'Identificação',
            sortable: true,
            selector: (row) => row.fisical_id,
        },
        {
            name: 'Loja',
            sortable: true,
            selector: (row) => row.store.label,
        },
        {
            name: 'Armazenada',
            sortable: true,
            selector: (row) => row.stored ? 'Sim' : 'Não',
        },
    ];

    const productsColumns = [
        {
            name: 'Id',
            width: '70px',
            center: true,
            selector: (row) => row.product_id,
            cell: (row) => (
                <TableCell
                    data={row.product_id}
                    tooltip={row.product_id}
                    canCopy
                />
            ),
            hide: 'sm',
        },
        {
            name: 'Produto',
            sortable: true,
            selector: (row) => row.label,
            cell: (row) => (
                <TableCell
                    data={row.label}
                    tooltip={row.label}
                />
            ),
        },
        {
            name: 'Loja',
            sortable: true,
            selector: (row) => stores?.find((store) => store.store_id === row.store_id)?.label,
        },
        {
            name: 'Quantidade',
            sortable: true,
            selector: (row) => row.quantity,
            hide: 'sm',
            center: true,
        },
        {
            name: 'Quantidade separada',
            sortable: true,
            selector: (row) => row.separated_quantity,
            hide: 'sm',
            center: true,
        },
    ];

    const boxProductColumns = [
        {
            name: 'Id',
            width: '70px',
            center: true,
            selector: (row) => row.product_id,
            cell: (row) => (
                <TableCell
                    data={row.product_id}
                    tooltip={row.product_id}
                    canCopy
                />
            ),
            hide: 'sm',
        },
        {
            name: 'Produto',
            sortable: true,
            selector: (row) => row.label,
        },
        {
            name: 'Quantidade',
            sortable: true,
            selector: (row) => row.quantity,
            center: true,
        },
    ];

    return (
        <Drawer anchor={'right'} open={visible} onClose={onClose}>
            <Box sx={{ width: { xs: '100%', md: '900px' }, padding: '50px 50px 0 100px' }}>
                {isLoading && <Spinner />}
                {!isLoading && separation &&
                    <>
                        <Stack
                            sx={{ pb: 2 }}
                            direction={'row'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    gap: '20px',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography variant={'h5'} sx={{ fontWeight: 'bold' }}>
                                    {'Separação #'}
                                    {separation.separation_id}
                                </Typography>
                                <Chip
                                    sx={{
                                        backgroundColor: status?.backgroundColor,
                                        fontSize: '12px',
                                        color: status?.color,
                                    }}
                                    size={'small'}
                                    label={status?.label}
                                />
                            </Box>
                            <IconButton onClick={onClose}>
                                <Close />
                            </IconButton>
                        </Stack>
                        <Box component={'div'} sx={{ display: 'flex', gap: '30px' }}>
                            <Box component={'div'}>
                                <Typography variant={'h3'} sx={{ fontSize: '20px', fontWeight: 'bold' }}>
                                    {'Operador'}
                                </Typography>
                                <Typography variant={'h4'} sx={{ fontSize: '18px' }}>
                                    {separation.user?.first_name
                                        ? `${separation.user.first_name || ''} ${separation.user.last_name || ''}`
                                        : '-'
                                    }
                                </Typography>
                            </Box>
                            <Box component={'div'}>
                                <Typography variant={'h3'} sx={{ fontSize: '20px', fontWeight: 'bold' }}>
                                    {'Gerada em'}
                                </Typography>
                                <Typography variant={'h4'} sx={{ fontSize: '18px' }}>
                                    {separation.activity_date
                                        ? new Date(separation.activity_date).toLocaleString('pt-BR', {
                                            day: '2-digit',
                                            month: '2-digit',
                                            year: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                        })
                                        : '-'
                                    }
                                </Typography>
                            </Box>
                            <Box component={'div'}>
                                <Typography variant={'h3'} sx={{ fontSize: '20px', fontWeight: 'bold' }}>
                                    {'Início'}
                                </Typography>
                                <Typography variant={'h4'} sx={{ fontSize: '18px' }}>
                                    {separation.start_date
                                        ? new Date(separation.start_date).toLocaleString('pt-BR', {
                                            day: '2-digit',
                                            month: '2-digit',
                                            year: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                        })
                                        : '-'
                                    }
                                </Typography>
                            </Box>
                            <Box component={'div'}>
                                <Typography variant={'h3'} sx={{ fontSize: '20px', fontWeight: 'bold' }}>
                                    {'Fim'}
                                </Typography>
                                <Typography variant={'h4'} sx={{ fontSize: '18px' }}>
                                    {separation.end_date
                                        ? new Date(separation.end_date).toLocaleString('pt-BR', {
                                            day: '2-digit',
                                            month: '2-digit',
                                            year: 'numeric',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                        })
                                        : '-'
                                    }
                                </Typography>
                            </Box>
                        </Box>
                        <Typography
                            variant={'h3'}
                            sx={{ fontSize: '20px', fontWeight: 'bold', marginTop: '26px' }}
                        >
                            {'Caixas'}
                        </Typography>
                        <DataTable
                            data={separation.boxes}
                            striped
                            columns={boxesColumns}
                            pagination
                            paginationPerPage={10}
                            progressPending={isLoading}
                            progressComponent={<Spinner />}
                            noDataComponent={'Nenhum dado cadastrado'}
                            expandableRows
                            expandableRowsComponent={({ data }) => (
                                <DataTable
                                    data={data.products}
                                    striped
                                    columns={boxProductColumns}
                                    pagination
                                    paginationPerPage={10}
                                    noDataComponent={(
                                        <Typography
                                            variant={'h4'}
                                            sx={{ fontSize: '18px', padding: '20px 0' }}
                                        >
                                            {'Nenhum produto na caixa'}
                                        </Typography>
                                    )}
                                    paginationComponentOptions={{
                                        noRowsPerPage: true,
                                        rangeSeparatorText: 'de',
                                    }}
                                />
                            )}
                            paginationComponentOptions={{
                                noRowsPerPage: true,
                                rangeSeparatorText: 'de',
                            }}
                        />
                        <Typography
                            variant={'h3'}
                            sx={{ fontSize: '20px', fontWeight: 'bold', marginTop: '26px' }}
                        >
                            {'Produtos'}
                        </Typography>
                        <DataTable
                            data={separation.products}
                            striped
                            columns={productsColumns}
                            pagination
                            paginationPerPage={10}
                            progressPending={isLoading}
                            progressComponent={<Spinner />}
                            noDataComponent={'Nenhum dado cadastrado'}
                            paginationComponentOptions={{
                                noRowsPerPage: true,
                                rangeSeparatorText: 'de',
                            }}
                        />
                    </>
                }
            </Box>
        </Drawer>
    );
};

export default SeparationDrawer;
