import { useMutation, useQuery } from '@tanstack/react-query';
import api from '../services';
import { queryClient } from 'services/queryClient';

export const useUserInformation = (token) => useQuery(['user-information', token], async () => {
    if (!token) {
        return [];
    }

    const response = await api.get('users/me', {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });

    return response.data.data;
}, {
    enabled: !!token,
    staleTime: Infinity,
});

export const usePatchUserInformation = () => useMutation(async (data) => {
    const response = await api.put('users/me', data);

    return response.data;
}, {
    onSuccess: (data) => {
        queryClient.invalidateQueries('user-information');
        return data;
    },
});

export const useRoles = () => useQuery(['roles'], async () => {
    const response = await api.get('roles', {
        params: {
            pagination: false,
        },
    });

    return response.data;
}, {
    staleTime: Infinity,
});

export const useZones = () => useQuery(['zones'], async () => {
    const response = await api.get('zones', {
        params: {
            pagination: false,
        },
    });

    return response.data;
}, {
    staleTime: Infinity,
});

export const useUsers = (page) => useQuery(['users', page], async () => {
    const response = await api.get('users', {
        params: {
            page,
            paginate: page ? undefined : false,
        },
    });
    return response.data;
}, {
    staleTime: Infinity,
    keepPreviousData: true,
});

export const useEditUser = () => useMutation(async (data) => {
    const response = await api.put('users', {
        ...data,
        password: data.password || undefined,
    });
    return response.data;
}, {
    onSuccess: (data) => {
        queryClient.invalidateQueries('users');
        return data;
    },
});

export const useCreateUser = () => useMutation(async (data) => {
    const response = await api.post('users', data);
    return response.data;
}, {
    onSuccess: (data) => {
        queryClient.invalidateQueries('users');
        return data;
    },
});

export const useDeleteUser = () => useMutation(async (userId) => {
    const response = await api.delete(`users/${userId}`);
    return response.data;
}, {
    onSuccess: () => {
        queryClient.invalidateQueries('users');
    },
});

export const useProducts = (page) => useQuery(['products', page], async () => {
    const response = await api.get('products', {
        params: {
            page,
        },
    });

    return response.data;
}, {
    keepPreviousData: true,
});

export const useSeparations = (future, page, startDate, endDate, userId) => useQuery(['separations', future, page, startDate, endDate, userId], async () => {
    const response = await api.get('separations', {
        params: {
            page,
            start_date: startDate && `${startDate.getFullYear()}-${startDate.getMonth()+1}-${startDate.getDate()}`,
            end_date: endDate && `${endDate.getFullYear()}-${endDate.getMonth()+1}-${endDate.getDate()}`,
            future: false === future ? false : undefined,
            user_id: userId,
        },
    });

    return response.data;
});

export const useSeparation = (separationId) => useQuery(['separation', separationId], async () => {
    if (!separationId) {
        return [];
    }

    const response = await api.get(`separations/${separationId}`);

    return response.data;
});

export const usePatchSeparation = (separationId, data) => useMutation(async () => {
    const response = await api.patch(`separations/${separationId}`, data);

    return response.data;
}, {
    onSuccess: () => {
        queryClient.invalidateQueries('separations');
    },
});

export const useOrders = (page, startDate, endDate) => useQuery(['orders', page, startDate, endDate], async () => {
    const response = await api.get('orders', {
        params: {
            page,
            start_date: startDate?.toISOString(),
            end_date: endDate?.toISOString(),
        },
    });

    return response.data;
}, {
    keepPreviousData: true,
});

export const useStores = (page, jobId) => useQuery(['stores', page, jobId], async () => {
    const response = await api.get('stores', {
        params: {
            page,
            job_id: jobId,
        },
    });

    return response.data;
}, {
    keepPreviousData: true,
});

export const useAllStores = () => useQuery(['stores'], async () => {
    const response = await api.get('stores', {
        params: {
            paginate: false,
        },
    });

    return response.data;
}, {
    keepPreviousData: true,
});

export const useStore = (storeId) => useQuery(['store', storeId], async () => {
    const response = await api.get(`stores/${storeId}`);

    return response.data;
});

export const usePatchStore = () => useMutation(async ({ storeId, data }) => {
    const response = await api.patch(`stores/${storeId}`, data);

    return response.data;
}, {
    onSuccess: () => {
        queryClient.invalidateQueries('stores');
    },
});

export const useJobs = () => useQuery(['jobs'], async () => {
    const response = await api.get('jobs', {
        params: {
            paginate: false,
        },
    });

    return response.data;
}, {
    refetchOnMount: false,
    keepPreviousData: true,
});

export const useJob = (jobId) => useQuery(['job', jobId], async () => {
    const response = await api.get(`jobs/${jobId}`);

    return response.data;
});

export const usePatchJob = () => useMutation(async ({ jobId, data }) => {
    const response = await api.patch(`jobs/${jobId}`, data);

    return response.data;
}, {
    onSuccess: () => {
        queryClient.invalidateQueries('jobs');
    },
});

export const useCreateJob = () => useMutation(async (data) => {
    const response = await api.post('jobs', data);

    return response.data;
}, {
    onSuccess: () => {
        queryClient.invalidateQueries('jobs');
    },
});

export const useDeleteJob = () => useMutation(async (jobId) => {
    const response = await api.delete(`jobs/${jobId}`);

    return response.data;
}, {
    onSuccess: () => {
        queryClient.invalidateQueries('jobs');
    },
});

export const useGenerateSeparations = () => useMutation(async ({ jobs, streets }) => {
    const response = await api.post('separation_process/run', {
        job_id: jobs,
        street_id: streets,
    });

    return response.data;
}, {
    onSuccess: () => {
        queryClient.invalidateQueries('separations');
    },
});
