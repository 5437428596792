import {
    createContext,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';

import { useUserInformation } from 'hooks/useApi';
import API from 'services';

const tokenKey = '@cobap:token';

export const AuthContext = createContext({});

const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(() => localStorage.getItem(tokenKey));

    const user = useUserInformation(token);

    const signIn = useCallback(async ({ username, password, keep_logged }) => {
        const response = await API.post('login', {
            username,
            password,
            keep_logged,
        });

        const { token: tokenResponse } = response.data.data;

        localStorage.setItem(tokenKey, tokenResponse);
        setToken(tokenResponse);
        API.defaults.headers.common.Authorization = tokenResponse
            ? `Bearer ${tokenResponse}`
            : null;
    }, []);

    const signOut = useCallback(() => {
        if (token) {
            API.post('logout', {});
        }

        localStorage.removeItem(tokenKey);
        setToken(undefined);
    }, [token]);

    useEffect(() => {
        API.defaults.headers.common.Authorization = token ? `Bearer ${token}` : null;
    }, [token]);

    useEffect(() => {
        const interceptor = API.interceptors.response.use(
            (response) => response,
            async (error) => {
                if (401 === error.response?.status) {
                    await signOut();
                    window.location.href = '/';
                }

                return Promise.reject(error);
            },
        );

        return () => {
            API.interceptors.response.eject(interceptor);
        };
    }, [signOut]);

    const isAuthenticated = !!token;

    const contextValue = useMemo(() => ({
        isAuthenticated,
        signIn,
        signOut,
        user: user.data,
    }), [isAuthenticated, signIn, signOut, user]);

    return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
