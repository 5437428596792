import React, { useContext, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import {
    IconButton,
    Drawer,
    Box,
    Typography,
    Divider,
    Stack,
    ListItemIcon,
    List,
    ListItemText,
} from '@mui/material';
import {
    AccountCircle,
    Close,
    Dashboard,
    ExitToApp,
    InventorySharp,
    Save,
    Settings,
    Store,
    Widgets,
} from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu';

import logo from './assets/logoPinheiro.png';
import logoIcon from './assets/logoPinheiroIcon.png';
import { PinheiroButton, PinheiroListItemButton, PinheiroTextField } from 'components/PinheiroTheme';
import { AuthContext } from 'contexts/AuthContext';
import { useForm } from 'react-hook-form';
import { usePatchUserInformation } from 'hooks/useApi';
import { capitalize } from 'utils/text';

const LayoutDashboard = () => {
    const [drawer, setDrawer] = useState(true);
    const [settings, setSettings] = useState(false);
    const location = useLocation();
    const path = location.pathname.replace('/', '');
    const navigate = useNavigate();

    const { user, signOut } = useContext(AuthContext);
    const editUser = usePatchUserInformation();

    const userForm = useForm({
        defaultValues: {
            first_name: capitalize(user?.first_name),
            last_name: capitalize(user?.last_name),
            email: user?.email?.toLowerCase(),
            password: '',
            new_password: '',
            new_password_confirmation: '',
        },
    });

    useEffect(() => {
        if (600 > window.innerWidth) {
            setDrawer(false);
        }
    }, []);

    useEffect(() => {
        userForm.reset({
            first_name: capitalize(user?.first_name),
            last_name: capitalize(user?.last_name),
            email: user?.email?.toLowerCase(),
            password: '',
            new_password: '',
            new_password_confirmation: '',
        });
    }, [user, userForm]);

    const handleSubmit = () => userForm.handleSubmit(async (data) => {
        await editUser.mutateAsync({
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            password: data.password || undefined,
        });
    });

    return (
        <>
            <Box component={'div'} sx={{ display: 'flex', width: '100%' }}>
                <Box
                    component={'aside'}
                    className={drawer ? 'active' : ''}
                    sx={{
                        'width': '0px',
                        'height': '100vh',
                        'backgroundColor': '#000000',
                        'position': 'relative',
                        'opacity': 0,
                        'visibility': 'hidden',
                        'transition': 'all 300ms ease',
                        '&.active': { width: '300px', opacity: 1, visibility: 'visible' },
                    }}
                >
                    <Box
                        component={'div'}
                        sx={{
                            width: '100%',
                            paddingLeft: '16px',
                            position: 'relative',
                            overflow: 'hidden',
                        }}
                    >
                        <Box
                            component={'img'}
                            src={logo}
                            sx={{ height: '120px', display: 'block' }}
                        />
                    </Box>
                    <Stack
                        direction={'column'}
                        justifyContent={'space-between'}
                        sx={{ height: 'calc(100% - 120px)' }}
                    >
                        <List>
                            <PinheiroListItemButton
                                className={'dashboard' === path ? 'active' : ''}
                                href={'/dashboard'}
                            >
                                <ListItemIcon>
                                    <Dashboard />
                                </ListItemIcon>
                                <ListItemText>{'Dashboard'}</ListItemText>
                            </PinheiroListItemButton>
                            <PinheiroListItemButton
                                className={'dashboard/separations' === path ? 'active' : ''}
                                href={'/dashboard/separations'}
                            >
                                <ListItemIcon>
                                    <InventorySharp />
                                </ListItemIcon>
                                <ListItemText primary={'Separações'} />
                            </PinheiroListItemButton>
                            <PinheiroListItemButton
                                className={'dashboard/employers' === path ? 'active' : ''}
                                href={'/dashboard/employers'}
                            >
                                <ListItemIcon>
                                    <AccountCircle />
                                </ListItemIcon>
                                <ListItemText primary={'Operadores'} />
                            </PinheiroListItemButton>
                            <PinheiroListItemButton
                                className={'dashboard/jobs' === path ? 'active' : ''}
                                href={'/dashboard/jobs'}
                            >
                                <ListItemIcon>
                                    <Store />
                                </ListItemIcon>
                                <ListItemText primary={'Jobs'} />
                            </PinheiroListItemButton>
                            <PinheiroListItemButton
                                className={'dashboard/products' === path ? 'active' : ''}
                                href={'/dashboard/products'}
                            >
                                <ListItemIcon>
                                    <Widgets />
                                </ListItemIcon>
                                <ListItemText primary={'Produtos'} />
                            </PinheiroListItemButton>
                        </List>
                        <List sx={{ borderTop: '1px solid #111111' }}>
                            <PinheiroListItemButton
                                onClick={() => {
                                    setSettings(!settings);
                                }}
                            >
                                <ListItemIcon>
                                    <Settings />
                                </ListItemIcon>
                                <ListItemText primary={'Configurações'} />
                            </PinheiroListItemButton>
                            <PinheiroListItemButton
                                onClick={async () => {
                                    await signOut();
                                    navigate('/');
                                }}
                            >
                                <ListItemIcon>
                                    <ExitToApp />
                                </ListItemIcon>
                                <ListItemText primary={'Sair'} />
                            </PinheiroListItemButton>
                        </List>
                    </Stack>
                </Box>
                <Box
                    component={'main'}
                    sx={{
                        width: '100%',
                        padding: { xs: '0 16px', md: '0 60px' },
                        position: 'relative',
                    }}
                >
                    <Stack
                        direction={'row'}
                        alignItems={'center'}
                        onClick={() => setDrawer(!drawer)}
                        sx={{ cursor: 'pointer', padding: '16px 0' }}
                    >
                        <IconButton
                            edge={'start'}
                            color={'inherit'}
                            aria-label={'menu'}
                            sx={{ mr: 2 }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Box
                            component={'img'}
                            src={logoIcon}
                            sx={{ height: '40px', mr: 2 }}
                        />
                        <Typography
                            variant={'h6'}
                            component={'div'}
                            sx={{ flexGrow: 1, color: '#008136', fontSize: { xs: '14px' } }}
                        >
                            {'Pinheiro supermercado'}
                        </Typography>
                    </Stack>
                    <Box
                        component={'section'}
                        sx={{
                            width: '100%',
                            height: 'calc(100vh - 72px)',
                            overflow: 'auto',
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            padding: { xs: '26px 16px 0 16px', md: '26px 60px 0 60px' },
                        }}
                    >
                        <Outlet />
                    </Box>
                </Box>
            </Box>
            <Drawer
                anchor={'right'}
                open={settings}
                onClose={() => setSettings(false)}
            >
                <Box sx={{ width: { xs: '100%', md: '350px' }, padding: '16px' }}>
                    <Stack
                        direction={'row'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                    >
                        <Typography variant={'h5'} sx={{ fontWeight: 'bold' }}>
                            {'Configurações'}
                        </Typography>
                        <IconButton onClick={() => setSettings(false)}>
                            <Close />
                        </IconButton>
                    </Stack>
                    <form
                        onSubmit={handleSubmit()}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '16px',
                                marginTop: '16px',
                            }}
                        >
                            <PinheiroTextField
                                label={'Nome'}
                                fullWidth
                                required
                                {...userForm.register('first_name', {
                                    required: 'Campo obrigatório',
                                })}
                            />

                            <PinheiroTextField
                                label={'Sobrenome'}
                                fullWidth
                                required
                                {...userForm.register('last_name', {
                                    required: 'Campo obrigatório',
                                })}
                            />

                            <PinheiroTextField
                                label={'Email'}
                                fullWidth
                                {...userForm.register('email', {
                                    email: 'Email inválido',
                                })}
                            />

                            <Divider />

                            <PinheiroTextField
                                type={'password'}
                                label={'Senha atual'}
                                variant={'outlined'}
                                placeholder={'******'}
                                fullWidth
                                {...userForm.register('password')}
                            />

                            <PinheiroTextField
                                type={'password'}
                                label={'Nova senha'}
                                variant={'outlined'}
                                placeholder={'******'}
                                fullWidth
                                {...userForm.register('new_password')}
                            />

                            <PinheiroTextField
                                type={'password'}
                                label={'Confirmar nova senha'}
                                variant={'outlined'}
                                placeholder={'******'}
                                fullWidth
                                {...userForm.register('new_password_confirmation', {
                                    required: '' !== userForm.getValues('new_password'),
                                    validate: (value) => {
                                        return (
                                            value === userForm.getValues('new_password') ||
                                            'As senhas não coincidem'
                                        );
                                    },
                                })}
                                error={!!userForm.errors?.new_password_confirmation}
                            />

                            <PinheiroButton
                                sx={{ width: '100%' }}
                                variant={'contained'}
                                size={'large'}
                                type={'submit'}
                                startIcon={<Save />}
                            >
                                {'Salvar alterações'}
                            </PinheiroButton>
                        </Box>
                    </form>
                </Box>
            </Drawer>
        </>
    );
};

export default LayoutDashboard;
